<template>
  <q-page class="q-pa-sm">
    <div class="row">

      <div class="col-md-6 col-xs-12 q-pa-xs">
        <q-item class="rounded-borders full-height text-white">
          <q-item-section>
            <q-input v-model="coupon"
                     type="text"
                     clearable

                     placeholder="______-_____-______"
                     @keydown.enter.prevent="() => searchCoupon()"
            >
              <template v-slot:prepend>
                <q-icon name="style"/>
              </template>
              <template v-slot:after>
                <q-btn
                  color="accent"
                  icon="search"
                  label="Найти"
                  type="submit"
                  @click="() => searchCoupon()"
                ></q-btn>
              </template>
            </q-input>
          </q-item-section>
        </q-item>
      </div>

      <div v-if="entity.code && coupon" class="col-12 q-pa-xs">
        <div class="rounded-borders full-height shadow-5 q-pa-md">
          <div>Код: {{entity.code}}</div>
          <div>Куплен: {{entity.purchased ? 'ДА' : 'нет'}}</div>
          <div>Использован: {{entity.used}}</div>
          <div>Название акции: {{entity.header}}</div>
          <div>Идентификатор пользователя в РСВ: {{ entity.rsv_user_id }}</div>
          <div>Когда использован: {{entity.activation_time}}</div>
          <div>Идентификатор купона: {{entity.coupon_id}}</div>
          <div>Идентификатор покупки: {{entity.order_id}}</div>
          <div>Идентификатор акции: {{entity.offer_id}}</div>
        </div>
      </div>

    </div>
  </q-page>
</template>

<script setup>
import { AdminApi } from '@/api/apis/admin-api.ts';
import { userStorage } from '@/lib/userStorage';
import { onMounted, ref } from 'vue';

const entity = ref({});
const coupon = ref(userStorage.lastCoupon || '');

const searchCoupon = async () => {
  try {
    const { data } = await new AdminApi().getCouponInfo(
      coupon.value,
    );

    const couponInfo = data;

    if (couponInfo.used) {
      couponInfo.used = 'ДА';
      couponInfo.activation_time = (new Date(couponInfo.activation_time)).toLocaleString();
    } else {
      couponInfo.used = 'нет';
    }

    if (!couponInfo.purchased) {
      couponInfo.activation_time = 'Купон не приобретён';
      couponInfo.used = 'Купон не приобретён';
      couponInfo.activation_time = 'Купон не приобретён';
      couponInfo.activation_time = 'Купон не приобретён';
      couponInfo.order_id = 'Купон не приобретён';
      couponInfo.phone = 'Купон не приобретён';
    }

    entity.value = couponInfo;

    userStorage.lastCoupon = coupon.value;
    console.log(data);
  } catch (e) {
    console.error(e);
  }
};

onMounted(() => coupon.value && searchCoupon());
</script>

<style>

</style>
